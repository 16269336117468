import React, { useRef } from 'react'
import styled from 'styled-components'
import {
  VARIABLE_CONSTANTS,
  VW
} from '../../styles'
import CartItem from './CartItem'
import ShippingThreshold from './ShippingThreshold'
// import CompleteRegimen from './CompleteRegimen'
import Checkout from './Checkout'
import Gifting from './Gifting'

const FilledCart = ({ subtotalPrice, webUrl, lineItems, note, customAttributes }) => {
  const checkout_ref = useRef()

  // const hasRegimen = !!lineItems.edges.filter(({ node: {
  //   title
  // }}) => {
  //   return title.includes('Regimen')
  // }).length

  return (
    <>
      <Wrapper checkoutHeight={checkout_ref.current ? checkout_ref.current.offsetHeight : '0'}>
        <ProductWrapper>
          <ShippingThreshold subtotalPrice={subtotalPrice} />
          {lineItems.edges.map((lineItem, i) => (
            <CartItem
              key={`${lineItem.node.title}-${i}`}
              lineItem={lineItem} />
          ))}
        </ProductWrapper>
        {/* {!hasRegimen && lineItems.edges.length !== 3  && <CompleteRegimen items={lineItems.edges} />} */}
        <Gifting note={note} customAttributes={customAttributes} />
      </Wrapper>
      <Checkout
        subtotalPrice={subtotalPrice}
        checkout_ref={checkout_ref}
        webUrl={webUrl} />
    </>
  )
}

const Wrapper = styled.div`
  display: block;
  padding: 0 ${VARIABLE_CONSTANTS.itemCartSpacing};
  min-height: calc(100vh -
    ${VARIABLE_CONSTANTS.mobileHeader}px - 
    ${props => props.checkoutHeight}px);

  @media (min-width: ${VW.desktop}) {
    min-height: calc(100vh -
      ${VARIABLE_CONSTANTS.desktopHeader}px - 
      ${props => props.checkoutHeight}px);
  }
`

const ProductWrapper = styled.div`
  & > div:last-child {
    margin-bottom: ${VARIABLE_CONSTANTS.itemCartSpacing};
  }
`

export default FilledCart
