import { useContext, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { replaceCheckout } from '../actions'
import { StoreContext } from '../contexts'
import { getLocalStorageCheckoutKey, localStorage } from '../utils'
import queryCheckout from '../shopify/queryCheckout'
import createCheckout from '../shopify/createCheckout'

const hydrateCheckout = async (client, storeSubdomain, dispatch) => {
  let checkoutId = localStorage().getItem(
    getLocalStorageCheckoutKey(storeSubdomain),
  )
  let checkout
  try {
    if (checkoutId) {
      const {
        data: { node },
      } = await queryCheckout(client, { id: checkoutId })

      if (node.orderStatusUrl) {
        const {
          data: {
            checkoutCreate: { checkout: shopifyCheckout },
          },
        } = await createCheckout(client, { input: {} })
        checkout = shopifyCheckout
      } else {
        checkout = node
      }
    } else {
      const {
        data: {
          checkoutCreate: { checkout: shopifyCheckout },
        },
      } = await createCheckout(client, { input: {} })
      checkout = shopifyCheckout
    }
  } catch (e) {
    console.log('Checkout issue', e)
    const {
      data: {
        checkoutCreate: { checkout: shopifyCheckout },
      },
    } = await createCheckout(client, { input: {} })
    checkout = shopifyCheckout
  }
  dispatch(replaceCheckout({ ...checkout, storeSubdomain }))
}

const useCheckoutHydration = () => {
  const dispatch = useDispatch()
  const checkouts = useSelector(({ checkouts }) => checkouts)
  const stores = useContext(StoreContext)

  /* eslint react-hooks/exhaustive-deps: off */
  useEffect(() => {
    Object.values(checkouts).forEach(({ id, storeSubdomain }) => {
      if (!id) {
        hydrateCheckout(stores[storeSubdomain].client, storeSubdomain, dispatch)
      }
    })
  }, [])
}

export default useCheckoutHydration
