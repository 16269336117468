import React, {
  useState,
  useCallback,
  useEffect
} from 'react'
import styled from 'styled-components'
import {
  typography,
  icons
} from '../../styles'

const Dial = ({
  initVal,
  updateCount,
  color
}) => {
  const [count, setCount] = useState(0)

  useEffect(() => {
    setCount(initVal)
  }, [initVal])

  const handleClick = useCallback((newCount) => {
    if(0 <= newCount && newCount <= 10) {
      setCount(newCount)
      updateCount(newCount)
    }
  }, [count, updateCount])

  return (
    <Styled_Dial color={color}>
      <div
        className="minus"
        onClick={() => handleClick(count - 1)} >
        {icons.minus(color)}
      </div>  
      <p>{count}</p>
      <div
        className="plus"
        onClick={() => handleClick(count + 1)} >
        {icons.increments(color)}
      </div>
    </Styled_Dial>
  )
}

const Styled_Dial = styled.div`
  position: relative;
  height: 42px;
  width: 82px;
  border: 1px solid ${props => props.color};
  user-select: none;
  box-sizing: border-box;

  .plus,
  .minus {
    position: absolute;
    top: 0;
    bottom: 0;
    width: calc(100% / 3);
    cursor: pointer;

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 8px;
      width: 8px;
    }
  }

  .plus {
    right: 0;
  }

  .minus{
    left: 0;
  }

  p {
    ${typography.body.small}
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
  }
`

export default Dial