import React from 'react'

const Logo = ({ isBlack }) => (
  <>
    {isBlack ? (
      <svg width="57" height="23" viewBox="0 0 57 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M52.4686 16.168C52.4686 18.4231 50.721 19.7319 48.8108 19.7319C47.2156 19.7319 46.3824 18.9064 46.3824 17.7486C46.3824 16.2284 48.0081 15.8258 49.6135 15.4633C51.0258 15.1311 52.0113 14.7586 52.4686 14.0942V16.168ZM55.6589 18.4835V11.8794C55.6589 8.04372 52.9563 6.33228 49.6033 6.33228C46.2199 6.33228 43.6086 8.28534 42.6536 10.9028L45.6915 12.6042C46.0878 10.5908 47.3477 9.04038 49.5627 9.04038C51.1274 9.04038 52.3263 9.92631 52.3263 11.2351C52.3263 12.423 51.0664 12.6948 48.821 13.1579C46.2402 13.7016 43.0397 14.5573 43.0397 17.9097C43.0397 20.7688 45.1327 22.4198 47.9268 22.4198C50.3552 22.4198 51.8284 21.0809 52.5092 20.044H52.6006C52.7226 20.7789 52.875 21.2621 53.3728 22.0574H56.0145C55.7504 21.0708 55.6589 20.1648 55.6589 18.4835Z" fill="black"/>
      <path d="M37.99 0H34.5457V22.0474H37.99V0Z" fill="black"/>
      <path d="M23.9685 19.8529C21.4182 19.8529 19.9754 18.1817 19.63 16.289L16.5615 18.0307C17.608 20.7086 20.2802 22.42 24.0599 22.42C27.1284 22.42 29.9022 20.6583 29.9022 17.7287C29.9022 14.8998 27.6567 13.8628 25.1369 13.0675C22.8915 12.3628 20.5851 12.1816 20.5851 10.6916C20.5851 9.50367 21.784 8.89963 23.0744 8.89963C25.1369 8.89963 26.4883 10.299 26.8236 11.8494L29.8311 10.1178C28.7236 7.8627 25.9904 6.34253 22.9118 6.34253C19.9653 6.34253 17.2524 8.07411 17.2524 10.8527C17.2524 13.7722 20.2294 14.8394 22.5663 15.4535C24.6594 16.0273 26.5594 16.3696 26.5594 17.92C26.5899 19.1884 25.391 19.8529 23.9685 19.8529Z" fill="black"/>
      <path d="M12.0501 6.69482H8.60571V22.0475H12.0501V6.69482Z" fill="black"/>
      <path d="M2.45883 16.752C3.82033 16.752 4.91766 15.6647 4.91766 14.3157C4.91766 12.9667 3.82033 11.8794 2.45883 11.8794C1.09733 11.8794 0 12.9667 0 14.3157C0 15.6546 1.09733 16.752 2.45883 16.752Z" fill="black"/>
      </svg>
    ) : (
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 254 103.95' fill='#000000'>
        <g data-name='Layer 2'>
          <g>
            <path
              d='M237.62 75c0 10.45-8.06 16.52-16.81 16.52-7.35 0-11.16-3.81-11.16-9.18 0-7.06 7.49-8.89 14.83-10.59 6.5-1.55 11-3.25 13.14-6.35zm-13.28-33c7.2 0 12.71 4.1 12.71 10.17 0 5.51-5.79 6.78-16.1 8.9-11.86 2.54-26.55 6.49-26.55 22 0 13.28 9.6 20.9 22.45 20.9a24.56 24.56 0 0021.05-11h.42a22.54 22.54 0 003.53 9.32H254c-1.27-4.52-1.69-8.76-1.69-16.53V55.08c0-17.79-12.43-25.7-27.83-25.7s-27.54 9-31.92 21.18l14 7.91c1.84-9.32 7.63-16.52 17.8-16.52m-69.07 60.31h15.82V0h-15.84zm-48.6-10.17c-11.73 0-18.37-7.77-19.92-16.53l-14.12 8.06C77.45 96 89.74 104 107.11 104c14.12 0 26.89-8.24 26.89-21.8 0-13.13-10.31-17.93-21.9-21.61-10.31-3.25-20.9-4.09-20.9-11 0-5.51 5.51-8.34 11.44-8.34a17.24 17.24 0 0117.23 13.7l13.84-8c-5.13-10.51-17.71-17.57-31.83-17.57-13.56 0-26 8.05-26 20.9 0 13.56 13.7 18.5 24.43 21.33 9.6 2.68 18.36 4.24 18.36 11.44 0 5.93-5.51 9-12 9m-70.6 10.21h15.82V31.07H36.07zM11.3 77.69A11.3 11.3 0 100 66.39a11.3 11.3 0 0011.3 11.3'
              className='cls-1'
            ></path>
          </g>
        </g>
      </svg>
    )}
  </>
)

export default Logo
