import React, { useMemo, useContext, useCallback } from 'react'
import { getPathname } from '../../utils';
import { LayoutContext } from '../../contexts';
import styled from 'styled-components'
import { Link } from 'gatsby';
import { typography } from '../../styles';
import Button from '../Button'
import useProductForm from '../../hooks/useProductForm';

const CartItemDisplay = ({
  title,
  price,
  thumbnail,
  isSmall,
  variant,
  handle
}) => {
  const { node_locale } = useContext(LayoutContext)

  const { AddProductToCart } = useProductForm({ handle })

  const formatedLink = useMemo(() => (
    getPathname(
      node_locale,
      `products/${title}`
        .toLowerCase()
        .replace(/ /g, '-')
    )
  ), [title])

  const formatedPrice = useMemo(() => (
    `$${Math.trunc(price)}`
  ), [price])

  const handleAdd = useCallback(() => {
    AddProductToCart(1)
  }, [AddProductToCart])

  const displayedVariants = useMemo(() => {
    let output = ''
    for (const { value } of variant) {
      output += !output ? value : ` / ${value}`
    }

    return output
  }, [variant])

  return (
    <CartItemDisplay_wrapper isSmall={isSmall}>
      <CartItemDisplay_container>
        <Image
          src={thumbnail}
          isSmall={isSmall} />
        <Content isSmall={isSmall}>
          <Title to={formatedLink}>
            {title}
          </Title>
          {displayedVariants !== 'Default Title' && (
            <p>{ displayedVariants }</p>
          )}
          <Price>{formatedPrice}</Price>
        </Content>
      </CartItemDisplay_container>
      {isSmall && (
        <Button_wrapper>
          <Button
            title='Add'
            type='small'
            color='white'
            triggerClick={handleAdd} />
        </Button_wrapper>
      )}
    </CartItemDisplay_wrapper>
  )
}

const CartItemDisplay_wrapper = styled.div`
  width: ${props => props.isSmall ? '50%' : '100%'};
`

const CartItemDisplay_container = styled.div`
  display: flex;
`

const Image = styled.img`
  height: ${props => props.isSmall ? '55' : '110'}px;
  width: ${props => props.isSmall ? '38' : '80'}px;
`

const Content = styled.div`
  ${props => props.isSmall ? {
    ...typography.body.tiny,
    letterSpacing: '0.5px'
  }
   : typography.body.big}
  margin: 0 15px;
`

const Title = styled(Link)``

const Price = styled.p``

const Button_wrapper = styled.div`
  margin-top: 15px;
  text-align: center;
  width: 50%;
`

export default CartItemDisplay