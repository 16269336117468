import { useContext } from 'react'
import { useSelector } from 'react-redux'
import { StoreContext } from '../contexts'
import updateGifting from '../shopify/updateGifting'

let debounce

const useUpdatedGifting = () => {
  const stores = useContext(StoreContext)
  const { storeSubdomain, checkouts } = useSelector(
    ({ storeSubdomain, checkouts }) => ({
      storeSubdomain,
      checkouts,
    })
  )

  const checkout = checkouts[storeSubdomain]
  const { client } = stores[storeSubdomain]
  
  const mainFunc = (isGift, note) => {

    const update = async () => {
      const input = typeof note === 'string' ? {
        note,
        customAttributes: [{
          key: 'Is this a gift?',
          value: isGift ? 'Yes' : 'No'
        }]
      } : {}
      // updateGifting(client, {
      //   checkoutId: checkout.id,
      //   input,
      // })
      const {
        data
      } = await updateGifting(client, {
        checkoutId: checkout.id,
        input,
      })
    }
    
    clearTimeout(debounce);
    debounce = setTimeout(update, 250)
  }

  return mainFunc
}

export default useUpdatedGifting
