import React, { useState } from 'react'

export const StoreContext = React.createContext([])

export const LayoutContext = React.createContext({})

export const DictionaryContext = React.createContext({})

export function LayoutProvider({ children, layout }) {
  const [changeHeader, setchangeHeader] = useState(true)

  return (
    <LayoutContext.Provider
      value={{
        ...layout,
        changeHeader,
        setchangeHeader,
      }}
    >
      {children}
    </LayoutContext.Provider>
  )
}
