import { gql } from 'apollo-boost'

const mutation = gql`
  mutation checkoutAttributesUpdateV2($checkoutId: ID!, $input: CheckoutAttributesUpdateV2Input!) {
    checkoutAttributesUpdateV2(checkoutId: $checkoutId, input: $input) {
      checkout {
        id
        note,
        customAttributes {
          key
          value
        }
      }
      checkoutUserErrors {
        code
        field
        message
      }
    }
  }
`

/**
 * https://help.shopify.com/en/api/storefront-api/reference/mutation/checkoutlineitemsreplace
 *
 * @param {{lineItems: [CheckoutLineItemInput], checkoutId: ID}} variables
 */
const updateGifting = (client, variables) =>
  client.mutate({ mutation, variables })

export default updateGifting
