import React, { useContext, useState } from 'react'
import { LayoutContext } from '../contexts'
import Logo from './graphics/Logo'
import subscribe from 'klaviyo-subscribe'
import { getPathname } from '../utils'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { VW, mobileVW, desktopVW, colors, baseMargin, sectionGrid, font } from '../styles'

const Footer = ({ hidden }) => {
  const {
    footerLinksInfoColumn,
    footerLinksSocialColumn,
    footerSignupPlaceholder,
    footerSignup,
  } = useContext(LayoutContext)

  const { node_locale } = useContext(LayoutContext)
  const [subscribed, setSubscribed] = useState(false)
  const [email, setEmail] = useState('')

  const validateEmail = email => {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }

  return (
    <StyledFooter>
      <Wrapper>
        <SiteMap>
          <div className="footer__inner">
            <div className="footer__nav">
              <ul className="footer__links">
                <li><a href="/en/products">Shop</a></li>
                <li><a href="/en/world">Explore</a></li>
                <li><a href="/en/about">About</a></li>
                <li><a href="/en/faqs">FAQ</a></li>
                <li><a href="/en/contact">Contact</a></li>
                <li className="footer__social"><a href="https://www.facebook.com/Isla-Beauty-107003310668861/" rel="noopener noreferer">Facebook</a></li>
                <li className="footer__social"><a href="https://www.instagram.com/islabeauty/" rel="noopener noreferer">Instagram</a></li>
              </ul>
              <div className="footer__tagline">Un-filtered. Un-jargoned. <br />Just the good stuff, in your inbox.</div>
            </div>
            <div className="footer__smallnav">
              <div className="footer__smallnavinner">
                <ul className="footer__smallnavlinks">
                  <li><a href="/en/privacy-policy">Privacy Policy</a></li>
                  <li><a href="/en/terms-and-conditions">Terms &amp; Conditions</a></li>
                </ul>
                <span>{(new Date()).getFullYear()} By ISLA</span>
                <span>All Rights Reserved</span>
              </div>
              <div className={`footer__signup ${subscribed && 'is-subscribed'}`}>
                <InputWrapper>
                  <Input
                    type='email'
                    placeholder={footerSignupPlaceholder}
                    value={subscribed ? 'You’re set! Check your inbox.' : email}
                    readOnly={subscribed}
                    onChange={e => setEmail(e.target.value)}
                  ></Input>
                  <Styledbutton
                    type={'submit'}
                    onClick={async e => {
                      if (validateEmail(email)) {
                        e.preventDefault()
                        await subscribe('JkMCKV', email)
                        setSubscribed(true)
                      }
                    }}
                  >
                    Send
                  </Styledbutton>
                </InputWrapper>
              </div>
            </div>
            <div className="footer__motto"><span>Show</span><span>Face</span></div>
          </div>
        </SiteMap>
      </Wrapper>
    </StyledFooter>
  )
}

const StyledFooter = styled.footer`
  background-color: ${colors.red};
  padding: 20px;
  color: ${colors.pink};
`

const Wrapper = styled.div`
  ${sectionGrid}
  margin-bottom: ${baseMargin.desktop};

  &:last-child {
    margin-bottom: 0;
  }
`

const SiteMap = styled.div`
  line-height: 1.86;
  display: grid;
  .footer__inner {
    padding: 25px 0;
    display: block;
    grid-column: span 4;
    @media (max-width: ${VW.desktop}) {
      padding: 0;
    }
  }
  .footer__smallnav {
    margin: 80px 0 180px 0;
    @media (max-width: ${VW.desktop}) {
      margin: 45px 0;
    }
  }
  .footer__nav, .footer__smallnav {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
  }
  .footer__motto {
    display: flex;
    font-size: 72px;
    line-height: 86px;
    color: #FFFFFF;
    justify-content: space-between;
    margin: 75px 0 0 0;
    @media (max-width: ${VW.desktop}) {
      flex-wrap: wrap;
      flex-direction: column;
      span:last-child {
        margin-left: auto;
        margin-top: 15px;
      }
    }
  }
  .footer__smallnavinner {
    font-family: ${font.typewriter};
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 125.5%;
    padding-top:22px;
    color: #FFFFFF;
    a {
      display: inline-block;
      position:relative;
      line-height: 1;
      &:after{
        content:"";
        width:0;
        position: absolute;
        bottom: 0;
        left:0;
        transition: all 0.2s ease-in-out;
        display: block;
        height: 1px;
        background: currentColor;
      }
      &:hover:after {
        width:100%;
      }
    }
    li {
      margin-bottom: 8px;
    }
  }
  .footer__links, .footer__smallnavinner {
    grid-column: span 3;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    @media (max-width: ${VW.desktop}) {
      grid-column: span 6;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 40px;
    }
    >li {
      font-family: Basel-Web;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 150%;
      color: #FFFFFF;

      @media (max-width: ${VW.desktop}) {
        font-size: 18px;
        line-height: 170%;
      }

      grid-column: 3;
      &:nth-child(1), &:nth-child(2) {
        grid-column: 1;
      } 
      &:nth-child(3), &:nth-child(4) {
        grid-column: 2;
      } 
      &:nth-child(3) {
        grid-row: 1;
        @media (max-width: ${VW.desktop}) {
          grid-row: 4;
          grid-column: 1;
        }
      }
      &:nth-child(4) {
        grid-row: 2;
        @media (max-width: ${VW.desktop}) {
          grid-row: 5;
          grid-column: 1;
        }
      }
      &:nth-child(5) {
        grid-row: 1;
        @media (max-width: ${VW.desktop}) {
          grid-column: 2;
        }
      }
      &:nth-child(6) {
        grid-row: 2;
        @media (max-width: ${VW.desktop}) {
          grid-column: 2;
        }
      }
      a {
        display: inline-block;
        position:relative;
        line-height: 1;
        &:after{
          content:"";
          width:0;
          position: absolute;
          bottom: 0;
          left:0;
          transition: all 0.2s ease-in-out;
          display: block;
          height: 1px;
          background: currentColor;
        }
        &:hover:after {
          width:100%;
        }
      }
    }
  }
  .footer__tagline {
    grid-column-start: 5;
    grid-column-end: 7;
    font-family: Basel-Web;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 130%;
    @media (max-width: ${VW.desktop}) {
      grid-column-start: 1;
      margin: 50px 0 0 0;
      font-size: 18px;
      line-height: 130%;
    }
    color: #FFFFFF;
  }
  .footer__signup {
    grid-column-start: 5;
    grid-column-end: 7;
    @media (max-width: ${VW.desktop}) {
      grid-column-start: 1;
      grid-row: 1;
      margin: 0 0 35px 0;
    }
  }
  .footer__smallnavinner {
    @media (max-width: ${VW.desktop}) {
      font-size: 10px;
      line-height: 175%;
      color: #FFFFFF;
    }
  }
  .footer__smallnavinner span {
    @media (max-width: ${VW.desktop}) {
      grid-column: 2;
      grid-row:1;
      &:nth-child(2) {
        margin-top: 1.5rem;
      }
    }
  }
  @media (max-width: ${VW.desktop}) {
    > div {
      margin-bottom: ${baseMargin.mobile};
    }

    &.footerBottom {
      margin-top: ${baseMargin.mobile};
      > div {
        margin-bottom: 0;
        p {
          margin: 0;
        }
      }
    }
  }

  @media (min-width: ${VW.desktop}) {
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 40px;
    align-items: baseline;
  }
`

const Column = styled.div`
  h4 {
    margin-top: 1rem;
    margin-bottom: 1rem;
    &:first-child {
      margin-top: 0;
    }
  }
  ul {
    line-height: 1.25em;
  }
`

const ListItem = styled.li``

const Input = styled.input`
  border: none;
  border-radius: 0;
  border-bottom: ${mobileVW(1.2)} solid #fff;
  width: 100%;
  line-height: 1.86;
  color: #fff;
  padding: ${mobileVW(5)} 0;
  font-size: ${mobileVW(14)};

  font-family: Basel-Web;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 130%;
  color: #FFFFFF;

  @media (min-width: ${VW.desktop}) {
    font-size: 20px;
  }



  @media (min-width: ${VW.desktop}) {
    padding: ${desktopVW(2)} 0;
    border-bottom: ${desktopVW(1.2)} solid #fff;
  }
  &[value] + button {
    opacity: 1;
  }
  +button, &[value=""] + button , &[readonly] + button{
    transition: all 0.2s ease-in-out;
    opacity: 0;
  }
  &[readonly] {
    background-position: center right;
    background-repeat: no-repeat;
    background-size: 24px 24px;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMjQgMjQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGNpcmNsZSBjeD0iMTIiIGN5PSIxMiIgcj0iMTIiIGZpbGw9IiNmZmYiLz48cGF0aCBkPSJNNyAxMi44Njk2TDEwLjM3NSAxNiAxOSA4IiBzdHJva2U9IiNFMzI4NDQiIHN0cm9rZS13aWR0aD0iMiIvPjwvc3ZnPg==");
  }
  

  &::placeholder {
    font-family: Basel-Web;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 130%;
    color: #FFFFFF;
    @media (min-width: ${VW.desktop}) {
      font-size: 20px;
    }
  }
`

const StyledLogo = styled.div`
  width: 100px;

  svg {
    fill: ${colors.white};
  }

  @media (max-width: ${VW.desktop}) {
    margin: ${baseMargin.mobile} 0;
  }
`

const InputWrapper = styled.div`
  position: relative;
  // margin: ${desktopVW(35)} 0 0 0;
`

const Styledbutton = styled.button`
  color: #fff;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  font-size: ${mobileVW(14)};
  cursor: pointer;

  @media (min-width: ${VW.desktop}) {
    font-size: ${desktopVW(14)};
  }
`

export default Footer
