import { combineReducers } from 'redux'
import { SELECT_STORE, REPLACE_CHECKOUT, SET_CART_OPEN } from './actions'

export const storeSubdomain = (state = '', { type, storeSubdomain }) =>
  type === SELECT_STORE ? storeSubdomain : state

export const checkouts = (state = {}, { type, checkout }) =>
  type === REPLACE_CHECKOUT
    ? {
        ...state,
        [checkout.storeSubdomain]: checkout,
      }
    : state
    

export const cartOpen = (state = false, { type, cartOpen }) =>
  type === SET_CART_OPEN ? cartOpen : state

const root = combineReducers({
  storeSubdomain,
  checkouts,
  cartOpen,
})

export default root
