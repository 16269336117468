import { gql } from 'apollo-boost'
import { checkout } from './fragments'

const mutation = gql`
  mutation createCheckout($input: CheckoutCreateInput!) {
    checkoutCreate(input: $input) {
      checkout {
        ...ShopifyCheckout
      }
    }
  }
  ${checkout}
`

/**
 * https://help.shopify.com/en/api/storefront-api/reference/mutation/checkoutcreate
 *
 * @param {{input: CheckoutCreateInput}} variables
 */
const createCheckout = (client, variables) =>
  client.mutate({ mutation, variables })

export default createCheckout
