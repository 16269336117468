import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import {
  typography,
  colors
} from '../styles'

const Button = ({
  title,
  link,
  type,
  color,
  disabled,
  reversedColor,
  triggerClick
}) => {
  let addedStyles
  if(type === 'small') {
    addedStyles = {
      padding: '5px 20px',
      'font-size': '15px'
    }
    
  } else if(type === 'medium') {
    addedStyles = {
      ...typography.body.small,
      padding: '10px 70px'
    }

  } else if(type === 'large') {
    addedStyles = {
      ...typography.body.big,
      padding: '15px 150px'
    }
  }

  // const hoverBg = color === 'white' ? 'white' : colors[color]

  if(reversedColor) {
    addedStyles = {
      ...addedStyles,
      'color': color === 'white' ? 'black' : 'white',
      'background-color': color === 'white' ? 'white' : colors[color],
      'border-color': color === 'white' ? 'white' : colors[color],

      '&:hover': {
        'color': color === 'white' ? 'white' : colors[color],
        'background-color': color === 'white' ? 'black' : 'transparent',
      }
    }

  } else {
    addedStyles = {
      ...addedStyles,
      'color': colors[color],
      // 'background-color': color !== 'transparent' ? color : 'white',
      'border-color': colors[color],

      '&:hover': {
        'color': color === 'white' ? 'black' : 'white',
        'background-color': color === 'white' ? 'white' : colors[color],
      }
    }
  }
  
  const handleClick = () => {
    if(disabled) return
    triggerClick()
  }

  return (
    <>
      {link ? (
        <Button_trigger_wrapper addedStyles={addedStyles}>
          <ButtonStyled_link
            className='button'
            disabled={disabled}
            to={link} >
            {title}
          </ButtonStyled_link>
        </Button_trigger_wrapper>
      ) : (
        
        <ButtonStyled_trigger
          className='button'
          disabled={disabled}
          onClick={handleClick}
          addedStyles={addedStyles} >
          {title}
        </ButtonStyled_trigger>
      )}
    </>
  )
}

const transProperty = '0.3s ease-in-out'

const buttonStyles = `
  border-width: 1px;
  border-style: solid;
  white-space: nowrap;
  transition: color ${transProperty},
              border-color ${transProperty},
              background-color ${transProperty};

  &[disabled] {
    background-color: gray !important;
    cursor: not-allowed;
    border-color: gray;
    color: white !important;
  }
`

const Button_trigger_wrapper = styled.div`
  a { ${props => props.addedStyles} }
`

const ButtonStyled_link = styled(Link)`
  ${buttonStyles}
`

const ButtonStyled_trigger = styled.div`
  ${buttonStyles}
  ${props => props.addedStyles}
  cursor: pointer;
`

export default Button