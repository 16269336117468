import React from 'react'
import * as R from 'ramda'
import ApolloClient from 'apollo-boost'
import fetch from 'isomorphic-fetch'
import { createStore, applyMiddleware } from 'redux'
import { Provider as ReduxProvider } from 'react-redux'
import ReduxThunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import { StoreContext } from '../contexts'
import rootReducer from '../reducers'

/**
 * @throws {Error}
 * @return {undefined}
 */
const validateStores = stores => {
  if (!(Array.isArray(stores) && stores.length > 0)) {
    throw new Error('No store is defined.')
  }

  // console.log(stores)

  if (stores.find(({ subdomain }) => !subdomain)) {
    throw new Error('"subdomain" is missing from one of the stores.')
  }

  if (stores.find(({ storefrontAccessToken }) => !storefrontAccessToken)) {
    throw new Error(
      '"storefrontAccessToken" is missing from one of the stores.',
    )
  }

  if (
    R.compose(
      R.not,
      R.equals(R.length(stores)),
      R.length,
      R.uniqBy(R.prop('subdomain')),
    )(stores)
  ) {
    throw new Error('Different stores share the same subdomain.')
  }
}

const Provider = ({ stores, children }) => {
  validateStores(stores)

  const shopifyStores = stores.reduce(
    (accumulator, store) => ({
      ...accumulator,
      [store.subdomain]: {
        ...store,
        client: new ApolloClient({
          uri: `https://${store.subdomain}.myshopify.com/api/2019-07/graphql.json`,
          headers: {
            'X-Shopify-Storefront-Access-Token': store.storefrontAccessToken,
          },
          fetch,
        }),
      },
    }),
    {},
  )

  const defaultStore = stores.find(store => store.default) || stores[0]

  const preloadedState = {
    storeSubdomain: defaultStore.subdomain,
    checkouts: Object.keys(shopifyStores).reduce(
      (accumulator, storeSubdomain) => ({
        ...accumulator,
        [storeSubdomain]: {
          storeSubdomain,
          id: null,
          webUrl: null,
          lineItems: { edges: [] },
          customAttributes: [],
        },
      }),
      {},
    ),
  }

  const reduxStore = createStore(
    rootReducer,
    preloadedState,
    composeWithDevTools(applyMiddleware(ReduxThunk)),
  )

  return (
    <StoreContext.Provider value={shopifyStores}>
      <ReduxProvider store={reduxStore}>{children}</ReduxProvider>
    </StoreContext.Provider>
  )
}

export default Provider
