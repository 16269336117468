import React, { useRef, useState, useEffect } from 'react'
import styled from 'styled-components'
import { colors, font, typography } from '../../styles'
import useUpdatedGifting from '../../hooks/useUpdateGifting'

const CHARACTOR_LIMIT = 200

const Gifting = ({note, customAttributes}) => {
  const input_ref = useRef()
  const textarea_ref = useRef()
  const [isGift, setIsGift] = useState(false)
  const [charCount, setCharCount] = useState(note && note.length || 0)

  const updateGifting = useUpdatedGifting()

  const handleInputClick = (event) => {
    if(event.target.localName !== 'input') { return }
    const isChecked = input_ref.current.checked
    textarea_ref.current.focus()
    setIsGift(isChecked)
    updateGifting(isChecked, textarea_ref.current.value)
  }

  const handleOnKeyUp = () => {
    const charCount = textarea_ref.current.value.length
    const isChecked = input_ref.current.checked
    if(charCount > CHARACTOR_LIMIT) { return }
    setCharCount(charCount)
    updateGifting(isChecked, textarea_ref.current.value)
  }
  useEffect(() => {
    const giftingValue = (customAttributes && customAttributes.length ? customAttributes : []).find(v => v && v.key === 'Is this a gift?' && v.value === 'Yes')
    if (textarea_ref && textarea_ref.current) {
      textarea_ref.current.value = note || ''
    }
    if (giftingValue) {
      input_ref.current.checked = true
      setIsGift(true)
    }
  }, [])

  return (
    <Gifting_wrapper>
      <Gifting_container>
        <Form>
          <label
            htmlFor="gifting"
            onClick={(event) => handleInputClick(event)} >
            Make it a gift?
            <input
              ref={input_ref}
              type="checkbox"
              id="gifting"
              value="Yes" />
            <span className='checkmark' />
          </label>
          <Text_wrapper hasHeight={isGift}>
            <CharacterLimit>{charCount}/{CHARACTOR_LIMIT}</CharacterLimit>
            <Text_container>
              <Text
                ref={textarea_ref}
                onKeyUp={handleOnKeyUp}
                placeholder='Enter your gift note text...'
                maxLength={CHARACTOR_LIMIT} />
            </Text_container>
          </Text_wrapper>
        </Form>
      </Gifting_container>
    </Gifting_wrapper>
  )
}

const Gifting_wrapper = styled.div`
  margin-bottom: 40px;
  padding: 8px;
  border-top: 1px solid ${colors.darkGrey};
`

const Gifting_container = styled.div`

`

const Form = styled.form`
  label {
    position: relative;
    display: inline-block;
    cursor: pointer;
    padding-left: 30px;
    user-select: none;
    margin-bottom: 8px;
  }
  
  input {
    opacity: 0;
    
    &,
    & + .checkmark,
    & + .checkmark::before {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }

    & + .checkmark {
      height: 18px;
      width: 18px;
      background-color: black;
      border-radius: 50%;
      border: 1px solid white;

      &::before {
        content: '';
        width: 10px;
        height: 10px;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 50%;
      }
    }

    &:checked + .checkmark:before {
      background-color: ${colors.red};
    }
  }

`

const Text_wrapper = styled.div`
  position: relative;
  overflow: hidden;
  height: ${props => props.hasHeight ? '120px' : '0'};
  transition: height 0.3s ease;
`

const Text_container = styled.div`
  position: relative;
  height: 100px;
`

const Text = styled.textarea`
  ${typography.body.tiny}
  resize: none;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 10px;
  font-family: ${font.typewriter};
`

const CharacterLimit = styled.p`
  ${typography.body.tiny}
  position: absolute;
  bottom: 0;
  right: 0;
  color: white;
`


export default Gifting