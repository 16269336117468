import React, { useContext, useEffect } from 'react'
import Image from '../shared/Image'
import { LayoutContext } from '../../contexts'
import { Link } from 'gatsby'

import styled from 'styled-components'
import {
  colors,
  icons,
  typography,
  VARIABLE_CONSTANTS,
} from '../../styles'

const Menu = ({
  menuOpen,
  items,
  onCloseMenu
}) => {
  useEffect(() => {
    if(typeof document !== 'undefined') {
      const body = document.querySelector('body')

      if(menuOpen) {
        body.classList.add('overflow')
      } else {
        body.classList.remove('overflow')
      }
    }
  }, [menuOpen])

  return (
    <StyledMenu visible={menuOpen}>
      <Wrapper>
        <MainMenuItems>
          {items.map(({
            title,
            slug
          }) => (
            <Container
              key={`Menu-${slug}`}
              onClick={onCloseMenu}
              to={slug} >
              <Title className={`hover`}>
                {title}
              </Title>
            </Container>
          ))}
        </MainMenuItems>
        <OtherMenuItems>
          <OtherMenuItems_container>  
            <OtherItem to='/en/faqs' onClick={onCloseMenu}>
              FAQ
            </OtherItem>
            <OtherItem to='/en/contact' onClick={onCloseMenu}>
              Contact
            </OtherItem>
          </OtherMenuItems_container>
          <StyledSvg>{icons.red_logo}</StyledSvg>
        </OtherMenuItems>
      </Wrapper>
    </StyledMenu>
  )
}

const StyledMenu = styled.div`
  background: #fff;
  top: ${VARIABLE_CONSTANTS.mobileHeader}px;
  bottom: 0;
  left: 0;
  position: fixed;
  width: 100%;
  z-index: 99;
  display: ${props => (props.visible ? 'block' : 'none')};
`

const MainMenuItems = styled.div``

const OtherMenuItems = styled.div`
  position: absolute;
  bottom: 30px;
  left: 40px;
  right: 40px;
`

const OtherMenuItems_container = styled.div`
  position: relative;
  `
  
  const OtherItem = styled(Link)`
  display: table;
  cursor: pointer;
  
  &:hover {
    color: ${colors.red}
  }
`

const Wrapper = styled.section`
  margin: 20px 0 0 40px;
`
const Container = styled(Link)`
  display: table;
  
  & + a {
    margin-top: 10px;
  }
`

const StyledImage = styled(Image)`
  
`

const Title = styled.h4`
  ${typography.headlines.articleAlternate}

  &:hover {
    color: ${colors.red}
  }
`

const StyledSvg = styled.div`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%)
`

export default Menu
