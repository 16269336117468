import React, { useContext, useEffect } from 'react'
import { Breakpoint, BreakpointProvider } from 'react-socks'
import { createGlobalStyle } from 'styled-components'
import NimBusItalicWoff from '../fonts/NimbusRomanNo9LRegularItalicU.woff'
import NimBusItalicWoff2 from '../fonts/NimbusRomanNo9LRegularItalicU.woff2'
import NimBusRegWoff from '../fonts/NimbusSanL-Reg-webfont.woff'
import NimBusBolWoff from '../fonts/NimbusSanL-Bol-webfont.woff'
import NimBusBolItalicWoff from '../fonts/NimbusSanL-BolIta-webfont.woff'

/* Basel */
import BaselBoldWoff from '../fonts/Basel-Bold.woff'
import BaselBoldWoff2 from '../fonts/Basel-Bold.woff2'
import BaselBookWoff from '../fonts/Basel-Book.woff'
import BaselBookWoff2 from '../fonts/Basel-Book.woff2'
import BaselTypewriterWoff from '../fonts/BaselTypewriter-Regular.woff'
import BaselTypewriterWoff2 from '../fonts/BaselTypewriter-Regular.woff2'

import Footer from './Footer'
import Header from './Header'
import Cart from './Cart'

import reset from 'styled-reset'
import {
  font,
  colors
} from '../styles'

const Layout = ({ children, location }) => {
  const bg = [
    'background: #fff;',
    'color: black',
    'padding: 10px 50px 10px 50px',
    'line-height: 35px',
    'font-family: helvetica',
    'font-size: 15px',
  ].join(';')
  console.log('%c © 2020 Isla — ALL RIGHTS RESERVED.', bg)

  return (
    <BreakpointProvider>
      <GlobalStyle />
      <Header location={location} />
      <Cart />
      <main>{children}</main>
      <Footer />
    </BreakpointProvider>
  )
}

const GlobalStyle = createGlobalStyle`
  ${reset}

  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed, 
  figure, figcaption, footer, header, hgroup, 
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video, button, input {
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    vertical-align: baseline;
  }

  @font-face {
    font-family: 'Basel-Web';
    src:
      url('${BaselBookWoff2}') format('woff2'),
      url('${BaselBookWoff}') format('woff');
    font-weight: 300;
    font-style: regular;
  }
  @font-face {
    font-family: 'Basel-Typewriter';
    src:
      url('${BaselTypewriterWoff2}') format('woff2'),
      url('${BaselTypewriterWoff}') format('woff');
    font-weight: 300;
    font-style: regular;
  }
  @font-face {
    font-family: 'Basel-Web';
    src:
      url('${BaselBoldWoff2}') format('woff2'),
      url('${BaselBoldWoff}') format('woff');
    font-weight: bold;
    font-style: regular;
  }

  @font-face {
    font-family: 'NimBusItalic';
    src:
      url('${NimBusItalicWoff2}') format('woff2'),
      url('${NimBusItalicWoff}') format('woff');
    font-weight: 300;
    font-style: regular;
  }

  @font-face {
    font-family: 'NimBusReg';
    src: url('${NimBusRegWoff}') format('woff');
    font-weight: 500;
    font-style: medium;
  }
  
  @font-face {
    font-family: 'NimBusBol';
    src: url('${NimBusBolWoff}') format('woff');
    font-weight: 500;
    font-style: medium;
  }

  @font-face {
    font-family: 'NimBusBolItalic';
    src: url('${NimBusBolItalicWoff}') format('woff');
    font-weight: 500;
    font-style: medium;
  }

  body {
    position: relative;
    height: 100%;
    font-family: Basel-Web;
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    &.overflow {
      overflow: hidden;
    }
  } 

  *:focus {
    outline:none;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  p {
    margin: 1em 0;
  }


  button,
  input {
    border: 0;
    background: transparent;
    margin: 0;
    padding: 0;
    outline: 0;
  }

  input,
  textarea,
  button,
  select,
  div,
  a {
    -webkit-tap-highlight-color: transparent;
  }
`

export default Layout
