module.exports = {
  getLocalStorageCheckoutKey: store => `checkouts::${store}`,
  getPathname: (locale, slug) => `/${locale}${slug ? `/${slug}` : ''}`,
  localStorage: () => {
    let localStorage
    try {
      if ('localStorage' in window) {
        localStorage = window.localStorage
      }
    } catch (error) {
      const { MemoryStorage } = require('./localMemoryStorage')
      console.warn('localStorage not present, falling back')
      localStorage = new MemoryStorage()
    }

    return localStorage
  },
  handleAnchorTags: (html) => {
    html = html.split('<a href')

    if(html.length > 1) {
      for (let i = 0; i < html.length; i++) {
        const item = html[i];
        if(item.charAt(0) === '=') {
          if(
            item.includes('isla-beauty.com') ||
            item.includes('islabeauty.netlify.app') ||
            item.includes('localhost')
          ) {
            html[i] = `<a href${item}`
            
          } else {
            html[i] = `<a target="_blank" rel="noopener noreferrer" href${item}`
          }
        }
      }
    }

    return html.join('')
  }
}
