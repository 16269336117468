import React, { useCallback } from 'react';
import Dial from '../shared/Dial';
import styled from 'styled-components'
import {
  colors,
  typography,
  VARIABLE_CONSTANTS
} from '../../styles'
import useReplaceLineItem from '../../hooks/useReplaceLineItem';
import CartItemDisplay from './CartItemDisplay';
import { localStorage } from '../../utils'

const CartItem = ({
  lineItem: {
    node: {
      title,
      quantity,
      variant: {
        id,
        selectedOptions: variant,
        presentmentPrices: {
          edges: [{
            node: {
              price: {
                amount
              } = {}
            } = {}
          } = {}]
        },
        image
      } = {}
    } = {}
  } = {}
}) => {
  const [ replaceLineItem ] = useReplaceLineItem()

  const updateCount = useCallback(newCount => {
    replaceLineItem({
      variantId: id,
      quantity: newCount,
    })
  }, [replaceLineItem, id])

  const handleRemove = useCallback(() => {
    replaceLineItem({
      variantId: id,
      quantity: 0,
    })
  }, [replaceLineItem, id])

  const transformedSrc = image && image.transformedSrc

  if(!title || !amount || !image) {
    console.error('Incomplete Product in Cart. Resetting Cart!')
    localStorage().clear()
    window.location.reload()
    return null
  }

  return (
    <CartItem_wrapper>
      <CartItemDisplay
        title={title}
        variant={variant}
        price={amount}
        thumbnail={transformedSrc} />
      <Quantity>
        <Dial
          updateCount={updateCount}
          initVal={quantity}
          color='#fff' />
        <Remove onClick={handleRemove}>
          Remove
        </Remove>
      </Quantity>
        
    </CartItem_wrapper>
  )
}

const CartItem_wrapper = styled.div`
  margin-top: ${VARIABLE_CONSTANTS.itemCartSpacing};
  display: flex;
  justify-content: space-between;

  @media (max-width: 500px) {
    display: block;
  }
`

const Quantity = styled.div`
  @media (max-width: 500px) {
    margin-left: 96px;
  }
`

const Remove = styled.span`
  ${typography.body.tiny}
  position: relative;
  color: ${colors.red};
  cursor: pointer;
  
  &::before {
    content: '';
    position: absolute;
    bottom: 2px;
    left: 0;
    width: 100%;
    border-bottom: 1px solid ${colors.red}
  }
`

export default CartItem