import React, { useContext } from 'react'
import styled from 'styled-components'
import { LayoutContext } from '../../contexts'
import {
  VW,
  typography,
  VARIABLE_CONSTANTS,
  font
} from '../../styles'

const HeaderBanner = ({ banner }) => {
  const { headerBanner: headerBannerContext } = useContext(LayoutContext)
  
  /** All Pages except Home & About : headerBannerContext.type === 1 */
  const isFixed = headerBannerContext.type === 1

  let firstIcon
  let secondIcon

  if(banner.firstIcon) {
    firstIcon = banner.firstIcon.fluid.src
  }

  if(banner.secondIcon) {
    secondIcon = banner.secondIcon.fluid.src
  }

  return (
    <HeaderBanner_wrapper isFixed={isFixed}>
      <HeaderBanner_container isFixed={isFixed}>
        <Content>
          <Item_wrapper>
            <Item image={firstIcon}>
              {banner && banner.firstText}
            </Item>
          </Item_wrapper>
          {banner && banner.secondIcon && banner.secondText && (
            <Item_wrapper>
              <Item image={secondIcon}>
                {banner.secondText}
              </Item>
            </Item_wrapper>
          )}
        </Content>
      </HeaderBanner_container>
    </HeaderBanner_wrapper>
  )
}

const HeaderBanner_wrapper = styled.div`
  ${props => props.isFixed ? `
    position: fixed;
    top: ${VARIABLE_CONSTANTS.mobileHeader}px;
    left: 0;
    width: 100%;
    height: ${VARIABLE_CONSTANTS.mobileBanner}px;
    background-color: white;
    z-index: 4;

    @media (min-width: ${VW.desktop}) {
      top: ${VARIABLE_CONSTANTS.desktopHeader}px;
      height: ${VARIABLE_CONSTANTS.desktopBanner}px;
    }
  ` : `
    position: absolute;
    width: 100%;
    height: calc(100% - ${VARIABLE_CONSTANTS.heroHeight});
    top: ${VARIABLE_CONSTANTS.heroHeight};
    z-index: 4;
    pointer-events: none;
  `}
`

const HeaderBanner_container = styled.div`
  ${props => props.isFixed ? `
    height: 100%;
  ` : `
    position: sticky;
    width: 100%;
    height: ${VARIABLE_CONSTANTS.mobileBanner}px;
    top: ${VARIABLE_CONSTANTS.mobileHeader}px
    left: 0;
    background-color: white;
    pointer-events: initial;

    @media (min-width: ${VW.desktop}) {
      height: ${VARIABLE_CONSTANTS.desktopBanner}px;
      top: ${VARIABLE_CONSTANTS.desktopHeader}px;
    }

  `}
`

const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  height: 100%;
`

const Item_wrapper = styled.div`
  padding: 0 25px;
`

const Item = styled.p`
  ${typography.body.tiny}
  font-family: ${font.typewriter};
  position: relative;
  padding-left: 38px;
  margin: 0;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 30px;
    height: 18px;
    background-repeat: no-repeat;
    background-position: right center;
    background-size: contain;
    background-image: url('${props => props.image}');
  }
`

export default HeaderBanner