import { getLocalStorageCheckoutKey, localStorage } from './utils'

export const SELECT_STORE = 'SELECT_STORE'
export const selectStore = storeSubdomain => (dispatch, getState) => {
  const storeSubdomains = Object.keys(getState().checkouts)
  if (storeSubdomains.find(subdomain => subdomain === storeSubdomain)) {
    dispatch({ type: SELECT_STORE, storeSubdomain })
  } else {
    throw new Error('The store is not found.')
  }
}

export const REPLACE_CHECKOUT = 'REPLACE_CHECKOUT'
export const replaceCheckout = (checkout) => (dispatch, getState) => {
  const storeSubdomains = Object.keys(getState().checkouts)

  if (
    storeSubdomains.find(
      storeSubdomain => storeSubdomain === checkout.storeSubdomain,
    )
  ) {
    localStorage().setItem(
      getLocalStorageCheckoutKey(checkout.storeSubdomain),
      checkout.id,
    )
    dispatch({
      type: REPLACE_CHECKOUT,
      checkout: {
        ...checkout,
        customAttributes: checkout.customAttributes.map(({ key, value }) => ({
          key,
          value,
        }))
      }
    })
  } else {
    throw new Error('The store is not found.')
  }
}

export const SET_CART_OPEN = 'SET_CART_OPEN'
export const setCartOpen = cartOpen => ({ type: SET_CART_OPEN, cartOpen })