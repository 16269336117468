import { gql } from 'apollo-boost'
import { checkout } from './fragments'

const mutation = gql`
  mutation replaceCheckoutLineItems(
    $lineItems: [CheckoutLineItemInput!]!
    $checkoutId: ID!
  ) {
    checkoutLineItemsReplace(lineItems: $lineItems, checkoutId: $checkoutId) {
      checkout {
        ...ShopifyCheckout
      }
    }
  }
  ${checkout}
`

/**
 * https://help.shopify.com/en/api/storefront-api/reference/mutation/checkoutlineitemsreplace
 *
 * @param {{lineItems: [CheckoutLineItemInput], checkoutId: ID}} variables
 */
const replaceCheckoutLineItems = (client, variables) =>
  client.mutate({ mutation, variables })

export default replaceCheckoutLineItems
