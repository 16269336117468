import { gql } from 'apollo-boost'
import { checkout } from './fragments'

const query = gql`
  query checkout($id: ID!) {
    node(id: $id) {
      ...ShopifyCheckout
    }
  }
  ${checkout}
`

/**
 * https://help.shopify.com/en/api/storefront-api/reference/queryroot
 *
 * @param {{id: ID}} variables
 */
const queryCheckout = (client, variables) => client.query({ query, variables })

export default queryCheckout
