import React from 'react'
import Provider from './src/components/Provider'
import { stores } from './site-config'
import {
  LayoutProvider,
  DictionaryContext
} from './src/contexts'
import Layout from './src/components/Layout'
import Client from './src/components/Client'
import LogRocket from 'logrocket'

if (process.env.NODE_ENV !== 'development' && false) {
  LogRocket.init('scv1j8/isla-headless')
}

export const wrapRootElement = ({ element }) => {
  return (
    <Provider stores={stores}>
      <Client>{element} </Client>
    </Provider>
  )
}

export const wrapPageElement = ({
  element,
  props: {
    location,
    pageContext: {
      layout,
      dictionary,
      headerBanner,
      products
    }
  },
}) => {
  return (
    <LayoutProvider layout={layout} value={layout}>
      <DictionaryContext.Provider value={dictionary}>
        <Layout location={location}>{element}</Layout>
      </DictionaryContext.Provider>
    </LayoutProvider>
  )
}
