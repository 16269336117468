import React, { useContext, useRef, useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { DictionaryContext, LayoutContext } from '../contexts'
import { setCartOpen } from '../actions'
import { TimelineMax, TweenMax, Power3 } from 'gsap'
import FilledCart from './cart/FilledCart'

import styled from 'styled-components'
import {
  VW,
  typography,
  icons,
  VARIABLE_CONSTANTS
} from '../styles'

const CLOSE_HEIGHT = '18';

const Cart = () => {
  const { bag } = useContext(DictionaryContext)
  const dispatch = useDispatch()
  const { node_locale, menuMain } = useContext(LayoutContext)
  const refWrapper = useRef(null)
  const refCard = useRef([])
  const [animationRunning, setanimationRunning] = useState(false)
  const cartOpen = useSelector(({ cartOpen }) => cartOpen)

  // const { lineItems, webUrl } = useSelector(
  const { lineItems, webUrl, note, customAttributes} = useSelector(
    ({ storeSubdomain, checkouts }) => checkouts[storeSubdomain],
  )

  const amountOfProducts = lineItems.edges.reduce(
    (acc, { node: { quantity } }) => {
      return acc + quantity
    },
    0,
  )

  const subtotalPrice = lineItems.edges.reduce(
    (
      acc,
      {
        node: {
          variant: {
            presentmentPrices: {
              edges: [
                {
                  node: {
                    price: { amount },
                  },
                },
              ],
            },
          },
          quantity,
        },
      },
    ) => {
      return acc + parseInt(amount * quantity, 10)
    },
    0,
  )

  useEffect(() => {
    if(typeof document !== 'undefined') {
      const body = document.querySelector('body')

      if(cartOpen) {
        body.classList.add('overflow')
      } else {
        body.classList.remove('overflow')
      }
    }
  }, [cartOpen])

  useEffect(() => {
    const tl = new TimelineMax({ paused: true })
    tl.call(() => setanimationRunning(true))
      .fromTo(
        refWrapper.current,
        1,
        {
          xPercent: 100,
        },
        {
          xPercent: 0,
          ease: Power3.easeInOut,
        },
      )
      .staggerFromTo(
        subtotalPrice ? [] : refCard.current,
        0.1,
        { autoAlpha: 0 },
        { autoAlpha: 1 },
        0.1,
      )

    cartOpen
      ? tl.play()
      : animationRunning
      ? tl.reverse(0)
      : TweenMax.set(refWrapper.current, { xPercent: 100, autoAlpha: 1 })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [animationRunning, cartOpen])

  

  return (
    <>
      <Cart_wrapper ref={refWrapper}>
        <Cart_container>
          <Cart_header>
            <Title>{`(${amountOfProducts}) ${bag}`}</Title>
            <Close onClick={() => dispatch(setCartOpen(false))}>
              {icons.close_x({
                color: '#fff',
                height: CLOSE_HEIGHT,
                width: '17'
              })}
            </Close>
          </Cart_header>
          <Cart_content>
            {subtotalPrice ? (
              <FilledCart
                subtotalPrice={subtotalPrice}
                webUrl={webUrl}
                lineItems={lineItems}
                note={note}
                customAttributes={customAttributes}
              />
            ) : (
              <>
                <TitleH2>You have no items in your cart.</TitleH2>
              </>
            )}
          </Cart_content>
        </Cart_container>
      </Cart_wrapper>
      <Overlay
        visible={cartOpen}
        onClick={() => dispatch(setCartOpen(false))} />
    </>
  )
}

const Cart_wrapper = styled.div`
  position: fixed;
  z-index: 101;
  top: 0;
  right: 0;
  height: 125vh;
  background: #000;
  width: 100vw;
  box-sizing: border-box;
  opacity: 0;
  max-width: 500px;
`

const Cart_container = styled.div`
  color: #fff;
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  background: #000;
  width: 100vw;
  overflow: scroll;
  box-sizing: border-box;
  max-width: 500px;
`

const Cart_header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${VARIABLE_CONSTANTS.mobileHeader}px;
  border-bottom: 2px solid white;
  padding: 0 25px;
  box-sizing: border-box;

  @media (min-width: ${VW.desktop}) {
    height: ${VARIABLE_CONSTANTS.desktopHeader}px;
  }
`

const Close = styled.div`
  cursor: pointer;
  height: ${CLOSE_HEIGHT}px;
`

const Title = styled.h2`
${typography.headlines.regular}
  position: relative;
  top: -3px;
  color: #fff;
  font-size: 20px;
  line-height: 120%;
`

const Cart_content = styled.section`
  position: relative;
  z-index: 13;

  p {
    margin: 0;
  }
`

// EMPTY CART SECTION
const TitleH2 = styled.div`
  ${typography.body.big}
  margin: 30px;
`

const Overlay = styled.div`
  z-index: 12;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  display: ${props => (props.visible ? 'block' : 'none')};
`

export default Cart
