import { useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { DictionaryContext } from '../contexts'
import useShopifyProduct from './useShopifyProduct'
import useReplaceLineItem from './useReplaceLineItem'
import { useDispatch } from 'react-redux'
import { setCartOpen } from '../actions'

const useProductForm = ({ handle }) => {
  const {
    addToCart,
    adding
  } = useContext(DictionaryContext)
  const dispatch = useDispatch()
  const { product } = useShopifyProduct(handle)
  // const checkout = useSelector(
  //   ({ storeSubdomain, checkouts }) => checkouts[storeSubdomain],
  // )
  const { lineItems } = useSelector(
    ({ storeSubdomain, checkouts }) => checkouts[storeSubdomain],
  )
  const [replaceLineItem, { loading }] = useReplaceLineItem()
  const [quantity, setQuantity] = useState(1)
  // const [variants, setVariants] = useState([])
  const [selectedVariant, setSelectedVariant] = useState()
  const [lineItem, setLineItem] = useState(null)

  useEffect(() => {
    if (product) {
      // setVariants(product.variants.edges)
      setSelectedVariant(product.variants.edges[0].node.id)
      setLineItem(
        lineItems.edges.find(
          ({
            node: {
              variant: { id },
            },
          }) => id === product.variants.edges[0].node.id,
        ),
      )
    }
  }, [lineItems.edges, product])

  useEffect(() => {
    setLineItem(
      lineItems.edges.find(
        ({
          node: {
            variant: { id },
          },
        }) => id === selectedVariant,
      ),
    )
  }, [lineItems.edges, selectedVariant])

  useEffect(() => {
    if (lineItem) {
      setQuantity(lineItem.node.quantity + 1)
    } else {
      setQuantity(1)
    }
  }, [lineItem])

  const AddProductToCart = async (count, variantId = null) => {
    await replaceLineItem({
      variantId: variantId || selectedVariant,
      quantity: count || quantity,
    })
    dispatch(setCartOpen(true))
    if (typeof window !== 'undefined' && window.fbq) {
      window.fbq('track', 'AddToCart', {
        content_name: handle
      })
    }
  }

  return {
    AddProductToCart: AddProductToCart,
    addToCart: addToCart,
    adding: adding,
    loading: loading || true,
    initialQuantity: lineItem && lineItem.node.quantity
  }
}

export default useProductForm
