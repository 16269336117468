import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import {
  typography,
  colors
} from '../../styles'

const ShippingThreshold = ({ subtotalPrice }) => {
  const [progressWidth, setProgressWidth] = useState(subtotalPrice || 0)

  useEffect(() => {
    setProgressWidth(subtotalPrice)
  }, [subtotalPrice])

  const threshold = 80
  const widthRatio = progressWidth / threshold

  const thresholdLeft = threshold - subtotalPrice
  let text

  if(thresholdLeft > 0) {
    text = `$${thresholdLeft} away from Free Standard Shipping`
  } else {
    text = `Congrats! You got free shipping`
  }

  return (
    <ShippingThreshold_wrapper>
      <Text>{text}</Text>
      <ProgressBar_wrapper>
        <ProgressBar ratio={widthRatio} />
      </ProgressBar_wrapper>
    </ShippingThreshold_wrapper>
  )
}

const ShippingThreshold_wrapper = styled.div`
  padding: 20px 0 35px;
  text-align: center;
  border-bottom: 1px solid ${colors.darkGrey};
`

const Text = styled.p`
  ${typography.body.small}
  color: white;
  margin: 0 0 10px !important;
`

const ProgressBar_wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 10px;
  background-color: white;
`

const ProgressBar = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: ${props => `calc(${props.ratio} * 100%)`};
  max-width: 100%;
  height: 10px;
  background-color: ${colors.red};
  transition: width 0.3s ease;
`

export default ShippingThreshold