import React, { useState, useContext, useEffect } from 'react'
import { Link } from 'gatsby'
import {
  DictionaryContext,
  LayoutContext
} from '../contexts'
import { setCartOpen } from '../actions'
import { getPathname } from '../utils'
import Logo from './graphics/Logo'
import { useDispatch, useSelector } from 'react-redux'
import Menu from './header/Menu'
import HeaderBanner from './header/HeaderBanner'

import styled from 'styled-components'
import {
  VW,
  colors,
  typography,
  icons
} from '../styles'

const Header = ({ location }) => {
  const {
    node_locale,
    template,
    headerBanner
  } = useContext(LayoutContext)

  const { bag } = useContext(DictionaryContext)

  const dispatch = useDispatch()
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const [changeHeader, setchangeHeader] = useState(true)
  const [transparancy, settransparancy] = useState(false)

  useEffect(() => {
    const animation = () => {
      return (
        settransparancy(false),
        (location.pathname === '/en' || location.pathname === '/en/about') &&
          (window.scrollY > 25 ? settransparancy(false) : settransparancy(true),
          window.scrollY > window.innerHeight
            ? setchangeHeader(false)
            : setchangeHeader(true))
      )
    }

    animation()
    window.addEventListener('scroll', animation)

    return () => {
      window.removeEventListener('scroll', animation)
      setchangeHeader(true)
    }
  }, [location.pathname, setchangeHeader, transparancy, location])

  const { lineItems } = useSelector(
    ({ storeSubdomain, checkouts }) => checkouts[storeSubdomain],
  )
  const amountOfProducts = lineItems.edges.reduce(
    (acc, { node: { quantity } }) => {
      return acc + quantity
    },
    0,
  )

  const menuItems = [
    {
      title: 'Shop',
      slug: getPathname(node_locale, 'products')
    },
    {
      title: 'Explore',
      slug: getPathname(node_locale, 'world')
    },
    {
      title: 'About',
      slug: getPathname(node_locale, 'about')
    }
  ]

  const burgerColor = transparancy ? '#fff' : '#000'

  return (
    <>
      <StyledHeader
        template={template}
        transparancy={transparancy}
        mobileMenuOpen={mobileMenuOpen} >
        <StyledHeader_container>
          <Background transparancy={transparancy} />
          <MenuText_wrapper>
            <div className="desktop">
              {menuItems.map(({ title, slug }) => (
                <MenuText
                  key={`menuItem-${slug}`}
                  to={slug}
                  transparancy={transparancy.toString()} >
                  {title}
                </MenuText>
              ))}
            </div>
            <div
              className="mobile"
              onClick={() => setMobileMenuOpen(!mobileMenuOpen)} >
                {!mobileMenuOpen ? (icons.burger_menu(burgerColor)) : (icons.close_x({ color: '#000' }))}
            </div>
          </MenuText_wrapper>
          <StyledLogo_wraper
            to={getPathname(node_locale, '')}
            onClick={() => setMobileMenuOpen(false)} >
            <StyledLogo transparancy={transparancy} transform={changeHeader.toString()}>
              <Logo isBlack={!transparancy || mobileMenuOpen} />
            </StyledLogo>
          </StyledLogo_wraper>
          <CartButton
            type='button'
            aria-label='open cart'
            onClick={() => {
              dispatch(setCartOpen(true))
              setMobileMenuOpen(false)
            }} >
            <span>({amountOfProducts})</span> {bag}
          </CartButton>
        </StyledHeader_container>
      </StyledHeader>
      <Menu
        menuOpen={mobileMenuOpen}
        onCloseMenu={() => setMobileMenuOpen(false)}
        items={menuItems} />
      {headerBanner && headerBanner.firstIcon && <HeaderBanner banner={headerBanner} />}
    </>
  )
}

const StyledHeader = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 4;
  padding: 15px 40px;
  line-height: 0.87;
  border-bottom: 2px solid
    ${props => (props.transparancy && !props.mobileMenuOpen ? '#fff' : '#000')};
  background-color: ${props => props.mobileMenuOpen ? '#fff' : 'transparent'};
  z-index: 100;

  ${props => props.template === 'Blog' ? `
    &::before {
      content: '';
      position: absolute;
      top: 8px;
      left: calc(50% - 19px);
      width: 150px;
      height: 150px;
      background-repeat: no-repeat;
      background-size: contain;
      background-image: url('${icons.world}');
      pointer-events: none;
    }
  ` : ''}

  @media (max-width: 480px) {
    &::before {
      display: none;
    }
  }

  @media (min-width: ${VW.desktop}) {
    padding: 21px 40px;
    background-color: transparent;
  }

  button,
  p,
  span {
    color: ${props => (props.transparancy && !props.mobileMenuOpen ? '#fff' : '#000')};
    transition: color 0.3s ease;
  }
`

const StyledHeader_container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const MenuText_wrapper = styled.div`
  .desktop {
    display: none;
    justify-content: space-between;
    width: 250px;
    // width: 150px;

    @media (min-width: ${VW.desktop}) {
      display: flex;
    }
  }

  .mobile {
    cursor: pointer;
    
    svg:hover {
      stroke:  ${colors.red}
    }

    @media (min-width: ${VW.desktop}) {
      display: none;
    }
  }
`

const MenuText = styled(Link)`
  ${typography.body.big}
  display: none
  cursor: pointer;
  transition: color 0.3s ease-in-out;

  &:hover {
    color: ${colors.red}
  }

  @media (min-width: ${VW.desktop}) {
    display: inline-block;
    color: ${props => props.transparancy === 'true' ? '#fff' : '#000'}
  }
`

const CartButton = styled.span`
  ${typography.body.big}
  cursor: pointer;

  &:hover {
    &,
    span {
      color: ${colors.red}
    }
  }
`

const StyledLogo_wraper = styled(Link)`
  height: 100%;
`

const StyledLogo = styled.div`
  position: fixed;
  top: 15px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9;
  display: flex;
  align-items: center;

  @media (min-width: ${VW.desktop}) {
    top: 18px;
  }

  svg {
    width: 60px;
    fill: ${props => (props.transparancy ? '#fff' : '#000')};
    @media (min-width: ${VW.desktop}) {

    }
  }
`

const Background = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: background 0.2s linear;
  will-change: background;
  z-index: -9;
  background: ${props => (props.transparancy ? 'transparent' : '#fff')};

  @media (min-width: ${VW.desktop}) {}
`

export default Header
