import React, { useContext } from 'react'
import { DictionaryContext, LayoutContext } from '../../contexts'
import Button from '../Button'
import styled from 'styled-components'
import {
  typography,
  colors,
  VARIABLE_CONSTANTS
} from '../../styles'

const Checkout = ({
  subtotalPrice,
  checkout_ref,
  webUrl
}) => {
  const { subtotal } = useContext(DictionaryContext)
  const { node_locale } = useContext(LayoutContext)

  const webUrlv2 = (webUrl
    ? webUrl.concat(`&locale=${node_locale.substring(0, 2)}`)
    : '').split('isla-us.myshopify.com').join('checkout.isla-beauty.com')

  return (
    <Checkout_wrapper ref={checkout_ref}>
      <Checkout_container>
        <Row>
          <p>{subtotal}</p>
          <p>${subtotalPrice}</p>
        </Row>
        <Row>
          <p>Estimated Total</p>
          <p>${subtotalPrice < 80 ? subtotalPrice + 6 : subtotalPrice}</p>
        </Row>
        <Button
          link={subtotalPrice === 0 ? null : webUrlv2}
          title='Proceed to Checkout'
          type='large'
          color='red'
          reversedColor />
        <Fees>Shipping &amp; promo codes calculated at the next step</Fees>
      </Checkout_container>
    </Checkout_wrapper>
  )
}

const Checkout_wrapper = styled.div`
  position: sticky;
  bottom: -1px;
  right: 0;
  width: 100%;
  border-top: 2px solid white;
  background-color: black;
  box-sizing: border-box;
`

const Checkout_container = styled.div`
  padding: 15px ${VARIABLE_CONSTANTS.itemCartSpacing} 85px;
  
  a {
    display: block;
    text-align: center;
    margin: 30px 0 10px;
    padding-left: 0;
    padding-right: 0;
  }
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  ${typography.body.big}
`

const Fees = styled.p`
  ${typography.body.tiny}
  color: ${colors.darkGrey}
`

export default Checkout